<template>
  <div class="my-page" id="my-page-cast">
    <card-header ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="my-page-content">
        <notification-copy v-if="isShowNotification" :toastText="toastText" />
        <div class="user-summary text-center">
          <UserSummary
            :propsUser="user_info"
            :propsType="typeSingle"
            :propsButton="false"
            :propsSex="2"
          />
        </div>
        <!-- <div class="my-info f-w6">
          <div>
            <span
              class="my-name"
              v-if="user_info.last_name_kanji && user_info.first_name_kanji"
              >{{
                user_info.last_name_kanji + user_info.first_name_kanji
              }}</span
            >
          </div>
        </div> -->
        <div
          class="position-relative d-flex justify-content-center align-items-center"
        >
          <div class="my-icon position-relative" @click="checkIdentity()">
            <!--            <span class="f-w6 mb-0">Superオファー状況</span>-->
            <span class="f-w6 mb-0">Superオファー</span>
            <div class="notifi position-absolute"></div>
          </div>
          <div class="filter-blur position-absolute"></div>
        </div>
        <div class="border" />
        <div class="list-options-info">
          <div class="option">
            <router-link to="/cast/my-page/profile">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-view-profile-active.svg"
                />
              </div>
              <div
                class="
                  option__text
                  f-w3
                  option__text-profile option__text--line
                "
              >
                プロフィー ルを見る
              </div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/edit/profile">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-edit-profile-active.svg"
                />
              </div>
              <div
                class="
                  option__text
                  f-w3
                  option__text-profile option__text--line
                "
              >
                プロフィー ル編集
              </div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/bd-history">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_mypage/coin.svg"
                />
              </div>
              <div class="option__text f-w3">コイン履歴</div>
            </router-link>
          </div>
          <div class="option" v-if="user_info.user_type === 2">
            <router-link to="/cast/my-page/point-history">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/PP-active.svg"
                />
              </div>
              <div class="option__text f-w3 option__text-buy">ポイント履歴</div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/ranking">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-rank-active.svg"
                />
              </div>
              <div class="option__text f-w3">ランキング</div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/tracks">
              <div class="div option__image">
                <div class="position-relative">
                  <img
                    class="option__div"
                    src="~@/assets/image/icon_menu_mypage/icon-golf-active.svg"
                  />
                  <div
                    v-if="totalNotReadFootprint > 0"
                    class="number-not-read position-absolute f-w3"
                    :class="
                      totalNotReadFootprint >= 100
                        ? 'notify-round'
                        : 'notify-circle'
                    "
                  >
                    {{ totalNotReadFootprint }}
                  </div>
                </div>
              </div>
              <div class="option__text f-w3">足跡</div>
            </router-link>
          </div>
          <div class="option" @click="$refs.shareApp.openModal()">
            <div class="div option__image position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-share-active.svg"
              />
            </div>
            <div class="option__text f-w3">シェア</div>
          </div>
          <ModalLayout ref="shareApp" :defaultStyle="false">
            <invite-friend @showNotification="showNotification" />
          </ModalLayout>
          <div class="option">
            <router-link to="/cast/my-page/blocked">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-block-men-active.svg"
                />
              </div>
              <div class="option__text f-w3">ブロック</div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/setting-notification">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-setting-active.svg"
                />
              </div>
              <div class="option__text option__text-notification f-w3">
                通知、表示設定
              </div>
            </router-link>
          </div>
          <div class="option">
            <router-link to="/cast/my-page/help">
              <div class="div option__image position-relative">
                <img
                  class="option__div"
                  src="~@/assets/image/icon_menu_mypage/icon-mypage-active.svg"
                />
              </div>
              <div class="option__text f-w3">ヘルプ</div>
            </router-link>
          </div>
          <div class="option" @click="$refs.modalLogout.openModal()">
            <div class="div option__image position-relative">
              <img
                class="option__div"
                src="~@/assets/image/icon_menu_mypage/icon-logout-active.svg"
              />
            </div>
            <div class="option__text f-w3">ログアウト</div>
          </div>
        </div>
        <ModalLayout ref="modalLogout">
          <p class="modal-logout-title">ログアウトしますか？</p>
          <div class="d-flex justify-content-center">
            <button @click.prevent="logOut" class="btn-logout">はい</button>
            <button @click="cancelLogOut" class="btn-cancel-logout">
              いいえ
            </button>
          </div>
        </ModalLayout>
        <ModalLayout ref="identifyPopup">
          <IdentifyPopup
            @identify="identify"
            :identity_status="user_info.identity_status"
            :sex="user_info.sex"
            :page="'my-page'"
          />
        </ModalLayout>
      </div>
    </div>
    <card-footer ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import InviteFriend from "@/components/InviteFriend.vue";
import CardFooter from "@/components/CardFooter.vue";
import Toast from "@/components/Toast";
import UserSummary from "@/components/UserSummary.vue";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import firebase from "firebase";
import IdentifyPopup from "@/components/IdentifyPopup.vue";

export default {
  name: "MyPage",
  components: {
    "card-header": CardHeader,
    "invite-friend": InviteFriend,
    "card-footer": CardFooter,
    "notification-copy": Toast,
    UserSummary,
    IdentifyPopup
  },
  metaInfo() {
    return {
      title: "マイページ",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      user: null,
      typeSingle: { type: "single" },
      headerTitle: {
        text: "マイページ",
        isShowTitle: true,
        isShowLogo: false,
        back: null
      },
      average: 115,
      age: 24,
      isShowNotification: false,
      toastText: "コピーしました",
      from: null
    };
  },
  async created() {
    this.$store.dispatch("auth/setShowToast", false);
    await this.getInfoUser();
    this.$store.dispatch("auth/getTotalNotReadFootprint");
    if (!this.user_info.post_code) {
      this.$store.dispatch("auth/setShowToast", true);
    }
  },
  computed: {
    ...mapGetters({
      user_info: "auth/user",
      isShowToast: "auth/isShowToast",
      totalNotReadFootprint: "auth/totalNotReadFootprint"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (
        from.name == "Withdrawal" ||
        from.name == "CastMyPagePointHistory" ||
        from.name == "CastMyPageBdHistory"
      ) {
        vm.headerTitle.back = "/cast";
      } else {
        vm.headerTitle.back = "/cast";
      }
    });
  },
  watch: {
    isShowToast(newVal) {
      if (newVal) {
        this.$toast(
          "プロフ画像, ニックネーム, 住所, ベストスコア等の必須項目をご入力ください",
          "通知",
          "success"
        );
        this.$store.dispatch("auth/setShowToast", false);
      }
    }
  },
  methods: {
    showNotification() {
      this.isShowNotification = true;
      setTimeout(() => {
        this.isShowNotification = false;
      }, 3000);
    },
    async getInfoUser() {
      this.user_info.isShowButton = true;
    },
    logOut() {
      this.$root.$refs.loading.start();
      Cookies.remove("prego_token");
      firebase.auth().signOut();
      this.$router.push({ name: "TopRegister" });
      this.$root.$refs.loading.finish();
    },
    cancelLogOut() {
      this.$refs.modalLogout.closeModal();
    },
    checkIdentity() {
      if (this.user_info.identity_status != 3) {
        this.$refs.identifyPopup.openModal();
        return;
      } else {
        this.$router.push({ path: "/cast/orders?tab=designation" });
      }
    },
    identify() {
      if (this.$route.name !== "CastMyPageIdentifyProfile")
        this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/cast/myPage.scss";
@import "~@/assets/scss/myPage.scss";
.content-scroll {
  overflow-x: hidden;
  // padding: 57px 0 100px 19px;
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}

.my-icon {
  width: 221px;
  margin: auto;
  margin-top: 30px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  box-shadow: 2px 3px 4px #26262659;
  border: 2px solid #464d77;
  cursor: pointer;
  span {
    color: #464d77;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
